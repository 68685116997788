<template>
    <div class="w-full h-full flex flex-col">
        <Header>
            <div>
                <h1>Avdelinger</h1>
                <h3 v-if="departments">Totalt {{departments.length}} {{departments.length > 1 ? 'avdelinger' : 'avdeling'}}.
					<a v-if="!showDeleted && departmentsAll && departmentsAll.find( d => d.deletedAt)" @click="showDeleted = !showDeleted">Vis slettede</a>
				</h3>
            </div>
        </Header>
        
        <div class="flex-grow overflow-auto p-6 md:p-12">
			
            <div 
                v-if="loading == 'fetching'"
                class="h-full flex items-center justify-center">
                
                <div class="spinner"></div>
            </div>
            <div 
                v-else-if="departments"
                class="
                    grid
                    gap-6 
                    md:gap-12
                    grid-cols-1
                    lg:grid-cols-2
                    xl:grid-cols-3
                ">
                
                <a 
                    v-for="d in showDeleted ? departmentsAll : departments"
                    :key="'department_'+d.departmentId"
                    @click="departmentEdit = d">
                    
                    <DepartmentCard 
                        :department="d"
                    />
                </a>
            </div>
            
        </div>
        
        <div class="absolute bottom-0 right-0 m-6">
            <button 
                @click="showDepartmentNew = true"
                class="button submit round">
                
                <i class="fas fa-plus" />
            </button>
        </div>
        
        
        
        <Modal 
            v-if="showDepartmentNew"
            @close="showDepartmentNew = false"
            insideClasses="bg-white">
            
            <header slot="header">
                Ny avdeling
            </header>
            
            <form 
                @submit.prevent="onCreateDepartment()"
                class="py-2 px-6">
                
                <InputSingleline 
                    v-model="departmentNew.name"
                    required="true"
                    label="Avdelingsnavn"
                    name="name"
                    showRequiredWarning="true"
                    ref="autofocus"
                />
            </form>
            
            <footer slot="footer">
                <div class="space-x-4 text-right">
                    <a 
                        class="button" 
                        @click="showDepartmentNew = false">
                        
                        Avbryt
                    </a>
                    
                    <button 
                        type="submit"
                        @click.prevent="onCreateDepartment()" 
                        :disabled="loading == 'creating'"
                        class="button submit"
                        :class="{'loading' : loading == 'creating'}">
                        
                        Opprett
                    </button>
                </div>
            </footer>
        </Modal>
        
        
        
        
        <div 
            v-if="departmentEdit"
            class="absolute inset-0 flex">
            
            <div 
                @click="departmentEdit = null"
                class="flex-grow bg-black opacity-25 ">
                
            </div>
            
            <transition name="fade">
                <DepartmentEdit 
                    :departmentId="departmentEdit.departmentId" 
                    @close="departmentEdit = null"
                    class="
                        absolute bg-white right-0 overflow-hidden w-full
                        md:rounded-l-lg 
                        md:w-150
                        md:shadow-2xl
                    "
                />
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            'DepartmentEdit': () => import('@/components/departments/DepartmentEdit.vue'),
            'DepartmentCard': () => import('@/components/departments/DepartmentCard.vue'),
        },
        
        computed:{
            departmentsAll(){
                return this.$store.getters.getAllDepartments;
            },
			
            departments(){
				return this.$store.getters.getDepartments;
            },
        },
        
        data(){
            return {
                departmentEdit: null,
                departmentNew: {
                    name: null,
                    icon: null,
                },
                showDepartmentNew: false,
                loading: null,
				showDeleted: false,
            }
        },
        
        methods: {
            async onCreateDepartment(){
                try {
                    if ( !this.departmentNew.name ) {
                        throw Error('En avdeling må ha et navn');
                    }
                    
                    this.loading = 'creating';
                    const department = await this.$store.dispatch('createDepartment', this.departmentNew);
                    console.log('department response', department);
                    
                    this.departmentNew = {
                        name: null,
                        icon: null,
                    };
                    
                    this.showDepartmentNew = false;
                    this.departmentEdit = department;
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        text: e.message || 'Kunne ikke opprette avdeling'
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.loading = null;
                }
            },
        },
        
        async mounted(){
            this.loading = 'fetching';
            
            await Promise.allSettled([
                this.$store.dispatch('listCampaigns'),
                this.$store.dispatch('listDepartments'),
            ]);
            
            this.loading = null;
        },
    }
</script>

<style lang="scss" >

</style>
